import React, { useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Input from "../../../../../components/inputs/Input";
import PrimaryButton from "../../../../../components/buttons/PrimaryButton";
import { useStepCreateEnelContext } from "../stepper.provider";
import { useLoginUser } from "../../../../../hooks/useLoginUser";
import { useLoginEnelService } from "../../../../../services/microservice/enel/auth/use-auth";
import ERROR from "../../../components/errors";
import { useModalContext } from "../../../../../providers/modal/modal.provider";
import ModalBody from "../../../components/ModalBody";

type IFormInput = {
  username: string;
  password: string;
  confirm: boolean;
};

const schemaLogin = yup
  .object({
    username: yup
      .string()
      .matches(/^\d+$/, { message: " Requerido" })
      .required(" Requerido"),
    password: yup.string().email().required(" Requerido"),
  })
  .required();

const LoginStepComponent = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
  } = useForm({
    resolver: yupResolver(schemaLogin),
  });
  const { setCurrentStep } = useStepCreateEnelContext();
  const { login } = useLoginUser();
  const { mutateAsync: loginEnel, isLoading } = useLoginEnelService();
  const { setShow } = useModalContext();
  const [error, setError] = useState<string>("");

  const handleLogin: SubmitHandler<IFormInput> = async (val) => {
    setShow((prevState) => ({
      ...prevState,
      visible: true,
      showBtnDecline: true,
      content: <ModalBody />,
      onAccept: () => onSubmit({ ...val, confirm: true }),
      onDecline: () => onSubmit({ ...val, confirm: false }),
      showBtnAccept: true,
      titleOnAccept: "Aceptar",
      titleOnDecline: "Rechazar",
    }));
  };

  const onSubmit = async (val: IFormInput) => {
    try {
      setShow(() => ({
        header: <></>,
        content: <></>,
        visible: false,
        showBtnAccept: false,
        showBtnDecline: false,
      }));
      const res = await loginEnel({
        username: val.username,
        password: val.password,
        sponsorID: "ENEL",
      });
      login(res.data, false);
      setCurrentStep(2);
    } catch (err) {
      if (err?.response?.status === 401 || err?.response?.status === 404) {
        setError(ERROR.UNAUTHORIZED);
      } else {
        setError(ERROR.INTERNAL);
      }
    }
  };

  return (
    <section className="w-full">
      <h2 className="mb-5 text-center text-3xl font-bold">
        1. <span style={{ color: "#461E7D" }}>Identifícate</span>
      </h2>
      {error && <p className="text-md text-critical">{error}</p>}
      <div className="mb-10 w-full">
        <form onSubmit={handleSubmit(handleLogin)}>
          <Controller
            name="username"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                classNameContainer="w-full mb-7"
                classNameInput="w-full"
                placeholder="Número de identificación"
                error={errors.username?.message}
                required
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                classNameContainer="w-full my-2"
                classNameInput="w-full"
                placeholder="Email"
                error={errors.password?.message}
                required
              />
            )}
          />

          <PrimaryButton
            title="INICIAR"
            className={
              "mt-5 rounded-3xl px-5 py-1 text-sm font-normal text-whiteSoft"
            }
            loading={isLoading}
            style={{ backgroundColor: "#461E7D" }}
          />
        </form>
      </div>
    </section>
  );
};

export default LoginStepComponent;
