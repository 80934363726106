import React from "react";
import { Link } from "react-router-dom";

import Logo from "../../../assets/images/samsung_care_plus.png";

const Navbar = () => {
  return (
    <nav className={`fixed z-40 w-full bg-white`}>
      <div className="max-w-screen flex lg:justify-between">
        <div className="flex w-full items-center justify-between px-5 lg:justify-center lg:px-0 xl:w-2/6 2xl:w-2/6">
          <Link to="/samsung-care-plus/reclamaciones">
            <div className="-ml-7 w-40 pt-5 lg:ml-0 lg:w-56 lg:pt-2">
              <img alt="acinco_logo" src={Logo} className="img-fluid" />
            </div>
          </Link>
        </div>
      </div>
    </nav>
  );
};
export default Navbar;
