import React from "react";
import { Link } from "react-router-dom";

import { ClaimProduct } from "./ClaimsGeneral.component";
import PrimaryButton from "../../../components/buttons/PrimaryButton";

const CardClaim = ({
  buttonTitle,
  secureImage,
  sponsorImage,
  buttonNavPath,
  hasSecureImage,
}: ClaimProduct) => {
  console.log(sponsorImage);
  return (
    <div className="my-4 flex flex-col items-center justify-center rounded-2xl bg-white p-10 py-20 shadow-xl">
      <div
        className={`mb-10 ${hasSecureImage ? "grid grid-cols-2 gap-4" : "flex items-center justify-center"} `}
      >
        {secureImage}
        {sponsorImage}
      </div>
      <Link to={buttonNavPath}>
        <PrimaryButton
          title={buttonTitle}
          className="rounded-xl bg-buttonPrimary text-white"
        />
      </Link>
    </div>
  );
};

export default CardClaim;
