import React, { ReactElement, ReactNode } from "react";
import CardClaim from "./card.claim.general";

import LogoAxa from "../../../assets/images/axa_colpatria.png";
import LogoSamsungCarePlus from "../../../assets/images/samsung_care.png";
import LogoMotorola from "../../../assets/images/Motorola_Logo_White.png";
import LogoMomSeguros from "../../../assets/images/mom_seguros_logo.jpg";
import LogoCamaraComercio from "../../../assets/images/logo_afiliados.png";
import LogoMiCel from "../../../assets/images/micelu_logo.jpg";
import LogoPladani from "../../../assets/images/pladani_logo.png";
import LogoViolet from "../../../assets/images/Enel_X_Logo_Violet_RGB.png";
import LogoThaniacel from "../../../assets/images/logo_thaniacel.png";

export interface ClaimProduct {
  nameProduct: string;
  buttonTitle: string;
  buttonNavPath: string;
  secureImage: ReactElement | ReactNode;
  sponsorImage: ReactElement | ReactNode;
  hasSecureImage: boolean;
}

const ClaimsComponent = () => {
  const claimsProducts: ClaimProduct[] = [
    {
      nameProduct: "axa-samsung",
      buttonTitle: "Haz Tu Reclamación",
      buttonNavPath: "/samsung-care-plus/reclamaciones",
      secureImage: (
        <img src={LogoAxa} alt="axa_colpatria" className="mx-auto max-h-20" />
      ),
      sponsorImage: (
        <img
          src={LogoSamsungCarePlus}
          alt="samsung_care_plus"
          className="mx-auto max-h-14"
        />
      ),
      hasSecureImage: true,
    },
    {
      nameProduct: "axa-enel",
      buttonTitle: "Haz Tu Reclamación",
      buttonNavPath: "/tramites-y-reclamaciones/axa",
      secureImage: <></>,
      sponsorImage: (
        <img
          src={LogoViolet}
          alt="enel_logo"
          className="lg:-mb-10 lg:-mt-10 lg:max-w-96"
        />
      ),
      hasSecureImage: false,
    },

    {
      nameProduct: "axa-motorola",
      buttonTitle: "Haz Tu Reclamación",
      buttonNavPath: "/tramites-y-reclamaciones/axa",
      secureImage: (
        <img src={LogoAxa} alt="axa_colpatria" className="mx-auto max-h-20" />
      ),
      sponsorImage: (
        <img src={LogoMotorola} alt="motorola" className="mx-auto max-h-20" />
      ),
      hasSecureImage: true,
    },
    {
      nameProduct: "axa-momseguros",
      buttonTitle: "Haz Tu Reclamación",
      buttonNavPath: "/tramites-y-reclamaciones/axa",
      secureImage: (
        <img src={LogoAxa} alt="axa_colpatria" className="mx-auto max-h-20" />
      ),
      sponsorImage: (
        <img
          src={LogoMomSeguros}
          alt="mom_seguros"
          className="mx-auto ml-5 max-h-24 lg:ml-10"
        />
      ),
      hasSecureImage: true,
    },
    {
      nameProduct: "axa-camaracomercio",
      buttonTitle: "Haz Tu Reclamación",
      buttonNavPath: "/tramites-y-reclamaciones/axa",
      secureImage: (
        <img src={LogoAxa} alt="axa_colpatria" className="mx-auto max-h-20" />
      ),
      sponsorImage: (
        <img
          src={LogoCamaraComercio}
          alt="camara_comercio_afiliados"
          className="mx-auto max-h-20"
        />
      ),
      hasSecureImage: true,
    },
    {
      nameProduct: "axa-micel",
      buttonTitle: "Haz Tu Reclamación",
      buttonNavPath: "/tramites-y-reclamaciones/axa",
      secureImage: <></>,
      sponsorImage: (
        <img src={LogoMiCel} alt="mi_cel_logo" className="max-h-24" />
      ),
      hasSecureImage: false,
    },
    {
      nameProduct: "axa-pladani",
      buttonTitle: "Haz Tu Reclamación",
      buttonNavPath: "/tramites-y-reclamaciones/axa",
      secureImage: <></>,
      sponsorImage: (
        <img
          src={LogoPladani}
          alt="pladani_logo"
          className="max-w-40 lg:max-w-64"
        />
      ),
      hasSecureImage: false,
    },
    {
      nameProduct: "axa-thaniacel",
      buttonTitle: "Haz Tu Reclamación",
      buttonNavPath: "/tramites-y-reclamaciones/axa",
      secureImage: <></>,
      sponsorImage: (
        <img
          src={LogoThaniacel}
          alt="thanicel_logo"
          className="max-w-40 lg:max-h-24 lg:max-w-full"
        />
      ),
      hasSecureImage: false,
    },
  ];
  return (
    <section className="flex w-full justify-center p-0">
      <div className="container w-full px-6 py-24 lg:px-20">
        <div className="grid grid-cols-1 gap-10 lg:grid-cols-2">
          {claimsProducts.map((product, index) => (
            <CardClaim key={`${product.nameProduct}_${index}`} {...product} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ClaimsComponent;
