import React from "react";

const ModalBody = () => {
  return (
    <div className="w-full justify-center">
      <p className="mx-10 my-5 text-center text-xl text-textBlack">
        Conforme a la ley 1581 del 2012: ¿Autoriza que sus datos personales,
        empresariales, u oficiales sean tratados por las empresas del Grupo ENEL
        en Colombia para el ofrecimiento de productos, servicios y demás fines
        de la política de tratamiento de datos, recuerde que tiene derecho a
        conocer, actualizar, rectificar y cuando proceda, suprimir sus datos y
        revocar su autorización, a través de los canales indicados en la
        política publicada en{" "}
        <a href="https://www.enel.com.co/" target="_blank">
          www.enel.com.co
        </a>{" "}
        en la cual también encontrará la identificación de las empresas que
        pertenecen al Grupo Enel en Colombia. ¿Está de acuerdo?
      </p>
    </div>
  );
};

export default ModalBody;
