import React, { useState, useEffect } from "react";
import { useDebounce } from "use-debounce";
import { useForm } from "react-hook-form";

import { useStepCreateEnelContext } from "../stepper.provider";
import Input from "../../../../../components/inputs/Input";
import PrimaryButton from "../../../../../components/buttons/PrimaryButton";
import { useImeiInfoService } from "../../../../../services/microservice/enel/imei/use-imei";
import { useCreateEnelContext } from "../../provider/createEnel.provider";
import ERRORS from "../../../components/errors";

const ImeiStepComponent = () => {
  const [imei, setImei] = useState<string>("");
  const [manufacter, setManufacter] = useState("");
  const [model, setModel] = useState("");
  const [errorLocal, setErrorLocal] = useState<string>("");
  const {
    register,
    formState: { errors },
    getValues,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      imei: "",
      model: "",
      manufacter: "",
    },
  });
  const [value] = useDebounce(imei, 200);
  const { setCurrentStep } = useStepCreateEnelContext();
  const {
    mutateAsync: postImeiInfo,
    isSuccess,
    isLoading,
  } = useImeiInfoService();
  const { setDevice } = useCreateEnelContext();

  useEffect(() => {
    const fetchApi = async () => {
      try {
        const res = await postImeiInfo({
          imei,
          sponsorID: "ENEL",
        });
        if (res) {
          setManufacter(res.data.brand);
          setModel(res.data.key);
          setDevice(res.data);
        }
      } catch (err) {
        if (err?.response?.status === 400) {
          if (
            err?.response?.data.error.message.includes("IMEI") ||
            err?.response?.data.error.message.includes("imei") ||
            err?.response?.data.error.message.includes("TAC") ||
            err?.response?.data.error.message.includes("precio")
          )
            setErrorLocal(ERRORS.ERROR_DEVICE);
        } else {
          setErrorLocal(ERRORS.INTERNAL);
        }
      }
    };
    if (getValues().imei !== "" && getValues().imei?.length === 15) {
      fetchApi();
    }
  }, [value]);

  const onSubmit = async () => {
    try {
      setCurrentStep(4);
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (e) => {
    if (e.target.value.length === 15) {
      setImei(e.target.value);
      setValue("imei", e.target.value);
    } else {
      clearErrors("imei");
      setManufacter("");
      setModel("");
      setErrorLocal("");
    }
  };

  const handlePreviusStep = () => {
    setCurrentStep(2);
  };
  return (
    <section className="w-full">
      <h2 className="mb-5 text-center text-3xl font-bold">
        3. <span style={{ color: "#461E7D" }}>Verifica</span> tu dispositivo
      </h2>
      <div className="mb-10 w-full">
        {errorLocal ? (
          <p className="text-normal text-critical">{errorLocal}</p>
        ) : null}
        <p className="text-md font-normal">
          Para conocer su imei marque en su celular *#06#
        </p>
        <Input
          {...register("imei", {
            required: {
              value: false,
              message: "* Requerido",
            },
            pattern: {
              value: /^\d+$/,
              message: "* Solo se aceptan números",
            },
            maxLength: {
              value: 15,
              message: "* El imei debe tener exactamente 15 digítos",
            },
            minLength: {
              value: 15,
              message: "* El imei debe tener exactamente 15 digítos",
            },
          })}
          classNameContainer="w-full mb-7"
          classNameInput="w-full [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
          placeholder="Número de IMEI"
          error={errors.imei?.message}
          id="imei"
          name="imei"
          type="number"
          required
          onChange={handleChange}
          loading={isLoading}
        />
        <Input
          {...register("model", {
            required: {
              value: false,
              message: " Requerido",
            },
          })}
          id="model"
          name="model"
          type="text"
          classNameContainer="w-full mb-7"
          classNameInput="w-full"
          placeholder="Nombre del dispositivo"
          disabled
          required
          value={model}
        />
        <Input
          {...register("manufacter", {
            required: {
              value: false,
              message: " Requerido",
            },
          })}
          id="manufacter"
          name="manufacter"
          type="text"
          placeholder="Marca del dispositivo"
          disabled
          classNameContainer="w-full mb-7"
          classNameInput="w-full"
          required
          value={manufacter}
        />
      </div>
      <div className="mt-4 flex justify-between">
        <PrimaryButton
          title="Atras"
          className="rounded-2xl border px-5 text-textBlack"
          onClick={() => handlePreviusStep()}
          type="button"
          style={{ borderColor: "#461E7D" }}
        />

        <PrimaryButton
          onClick={() => onSubmit()}
          title="Siguiente"
          className="rounded-2xl px-5 text-white"
          style={{ backgroundColor: "#461E7D", opacity: isSuccess ? 1 : 0.2 }}
          disabled={!isSuccess}
        />
      </div>
    </section>
  );
};

export default ImeiStepComponent;
