import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "./components/navbar";
import Banner from "./components/banner";
import Claims from "../../components/claims/Claims";
import Footer from "./components/footer";
import FAQComponent from "./components/faqcomponent";
import CampaignComponent from "./components/tableCampaign";

const ClaimsSamsungCarePlusPage = () => {
  return (
    <>
      <Helmet>
        <title>Reclamaciones | Samsung Care +</title>
        <meta
          name="description"
          content="¡Es muy simple! Con tan solo 5 sencillos pasos puedes realizar tu reclamación."
        />
      </Helmet>
      <Navbar />
      <Banner />
      <section className="flex w-full justify-center p-0">
        <div className="container w-full px-4 py-20 lg:px-20">
          <div className="rounded-xl py-5 shadow-xl lg:px-10">
            <Claims colorPrimary="#0F0F0F" dobleAuth={true} />
          </div>
        </div>
      </section>

      <CampaignComponent />
      <FAQComponent />
      <Footer />
    </>
  );
};
export default ClaimsSamsungCarePlusPage;
