import React, { ReactNode, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

interface ICampaign {
  name: JSX.Element | ReactNode;
  reference: JSX.Element | ReactNode;
  registerDate: string;
  startInitialDate: string;
  endInitialDate: string;
  startExtendedDate: string;
  endExtendedDate: string;
}

const campaign: ICampaign[] = [
  {
    name: <p>Beneficio Foldables 4 Plan Basico 12 meses</p>,
    reference: <p>SAMSUNG GALAXY Z FLIP 4 -SAMSUNG GALAXY Z FOLD 4</p>,
    registerDate: "Septiembre 2022 a Enero 2023",
    startInitialDate: "Septiembre 2022 a Enero 2023",
    endInitialDate: "Septiembre 2023 a Enero 2024",
    startExtendedDate: "Septiembre 2023 a Enero 2024",
    endExtendedDate: "Septiembre 2024 a Enero 2025",
  },
  {
    name: <p>Beneficio Samsung S23 Plan Basico 12 meses</p>,
    reference: (
      <p>SAMSUNG GALAXY S23 - SAMSUNG GALAXY S23+ - SAMSUNG GALAXY S23 ULTRA</p>
    ),
    registerDate: "Febrero 2023 a Abril 2023",
    startInitialDate: "Febrero 2023 a Abril 2023",
    endInitialDate: "Febrero 2024 a Abril 2024",
    startExtendedDate: "Febrero 2024 a Abril 2024",
    endExtendedDate: "Febrero 2025 a Abril 2025",
  },
  {
    name: <p>Beneficio Foldables 5 Plan Basico 12 meses</p>,
    reference: <p>SAMSUNG GALAXY Z FLIP 5 - SAMSUNG GALAXY Z FOLD 5</p>,
    registerDate: "Agosto 2023 a Diciembre 2023",
    startInitialDate: "Agosto 2023 a Diciembre 2023",
    endInitialDate: "Agosto 2024 a Diciembre 2024",
    startExtendedDate: "Agosto 2024 a Diciembre 2024",
    endExtendedDate: "Agosto 2025 a Diciembre 2025",
  },
  {
    name: <p>Beneficio TAB S9 Plan basico 12 meses</p>,
    reference: (
      <p>
        SAMSUNG GALAXY TAB S9 - SAMSUNG GALAXY TAB S9 PLUS - SAMSUNG GALAXY TAB
        S9 ULTRA
      </p>
    ),
    registerDate: "Agosto 2023 a Diciembre 2023",
    startInitialDate: "Agosto 2023 a Diciembre 2023",
    endInitialDate: "Agosto 2024 a Diciembre 2024",
    startExtendedDate: "Agosto 2024 a Diciembre 2024",
    endExtendedDate: "Agosto 2025 a Diciembre 2025",
  },
  {
    name: <p>Beneficio Samsung S24 Plan Basico 12 meses</p>,
    reference: (
      <p>SAMSUNG GALAXY S24 - SAMSUNG GALAXY S24+ - SAMSUNG GALAXY S24 ULTRA</p>
    ),
    registerDate: "Enero 2024",
    startInitialDate: "Enero 2024",
    endInitialDate: "Enero 2025",
    startExtendedDate: "Enero 2025",
    endExtendedDate: "Enero 2026",
  },
  {
    name: <p>Compra Plan Basico 24 meses Samsung S24 </p>,
    reference: (
      <p>SAMSUNG GALAXY S24 - SAMSUNG GALAXY S24+ - SAMSUNG GALAXY S24 ULTRA</p>
    ),
    registerDate: "Marzo 2024",
    startInitialDate: "Marzo 2024",
    endInitialDate: "Marzo 2026",
    startExtendedDate: "Marzo 2026",
    endExtendedDate: "Marzo 2027",
  },
  {
    name: <p> Compra Plan Platino 12 meses Samsung S24</p>,
    reference: (
      <p>SAMSUNG GALAXY S24 - SAMSUNG GALAXY S24+ - SAMSUNG GALAXY S24 ULTRA</p>
    ),
    registerDate: "Marzo 2024  a Junio 2024",
    startInitialDate: "Marzo 2024 a Junio 2024",
    endInitialDate: "Marzo 2025 a Junio 2025",
    startExtendedDate: "Marzo 2025 a Junio 2025",
    endExtendedDate: "Marzo 2026 a Junio 2026",
  },
  {
    name: <p>Compra Plan Platino 24 meses Samsung S24 </p>,
    reference: (
      <p>SAMSUNG GALAXY S24 - SAMSUNG GALAXY S24+ - SAMSUNG GALAXY S24 ULTRA</p>
    ),
    registerDate: "Marzo 2024  a Junio 2024",
    startInitialDate: "Marzo 2024 a Junio 2024",
    endInitialDate: "Marzo 2026 a Junio 2026",
    startExtendedDate: "Marzo 2025 a Junio 2025",
    endExtendedDate: "Marzo 2027 a Junio 2027",
  },
  {
    name: <p>Beneficio Foldables 6 Plan Basico 12 meses</p>,
    reference: <p>SAMSUNG GALAXY Z FLIP 6 - SAMSUNG GALAXY Z FOLD 6</p>,
    registerDate: "Septiembre 2024 a Octubre 2024",
    startInitialDate: "Septiembre 2024 a Octubre 2024",
    endInitialDate: "Septiembre 2025 a Octubre 2025",
    startExtendedDate: "Septiembre 2025 a Octubre 2025",
    endExtendedDate: "Septiembre 2026 a Octubre 2026",
  },
];

const CampaignComponent = () => {
  const [visible, setVisible] = useState<boolean>(false);
  return (
    <section className="flex w-full justify-center p-0">
      <div className="container w-full px-4 lg:px-20">
        <div className="rounded-xl py-5 shadow-lg lg:px-10">
          <div
            className="mx-5 flex justify-between lg:mr-10"
            onClick={() => setVisible((prevState) => !prevState)}
          >
            <h2 className="ml-10 text-xl font-bold text-buttonBlack lg:text-3xl">
              Campañas Samsung Care+
            </h2>
            <button
              className={
                visible
                  ? "rotate-180 transition-all ease-out"
                  : "rotate-0 transition-all ease-out"
              }
            >
              <KeyboardArrowDownIcon />
            </button>
          </div>
          {visible && (
            <>
              <div className="hidden lg:block">
                <Table className="my-10">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">CAMPAÑA</TableCell>
                      <TableCell align="center">REFERENCIA</TableCell>
                      <TableCell align="center">
                        REGISTRO DE ACTIVACION CAMPAÑA
                      </TableCell>
                      <TableCell align="center">
                        INCIO DE VIGENCIA POLIZA
                      </TableCell>
                      <TableCell align="center">
                        FIN DE VIGENCIA POLIZA
                      </TableCell>
                      <TableCell align="center">
                        INCIO DE VIGENCIA GARANTIA EXTENDIDA
                      </TableCell>
                      <TableCell align="center">
                        FIN DE VIGENCIA GARANTIA EXTENDIDA
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {campaign.map((camp) => (
                      <TableRow key={`Camp_${camp.registerDate}`}>
                        <TableCell>{camp.name}</TableCell>
                        <TableCell>{camp.reference}</TableCell>
                        <TableCell>{camp.registerDate}</TableCell>
                        <TableCell>{camp.startInitialDate}</TableCell>
                        <TableCell>{camp.endInitialDate}</TableCell>
                        <TableCell>{camp.startExtendedDate}</TableCell>
                        <TableCell>{camp.endExtendedDate}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
              <div className="px-4 py-10 sm:px-10 lg:hidden">
                <Table>
                  <TableBody>
                    {campaign.map((camp) => (
                      <>
                        <TableRow>
                          <TableCell>
                            <b>CAMPAÑA</b>
                          </TableCell>
                          <TableCell>{camp.name}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <b>REFERENCIA</b>
                          </TableCell>
                          <TableCell>{camp.reference}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <b>REGISTRO DE ACTIVACION CAMPAÑA</b>
                          </TableCell>
                          <TableCell>{camp.registerDate}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <b>INCIO DE VIGENCIA POLIZA</b>
                          </TableCell>
                          <TableCell>{camp.startInitialDate}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <b>FIN DE VIGENCIA POLIZA</b>
                          </TableCell>
                          <TableCell>{camp.endInitialDate}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <b>INCIO DE VIGENCIA GARANTIA EXTENDIDA</b>
                          </TableCell>
                          <TableCell>{camp.startExtendedDate}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <b> FIN DE VIGENCIA GARANTIA EXTENDIDA</b>
                          </TableCell>
                          <TableCell>{camp.endExtendedDate}</TableCell>
                        </TableRow>
                        <TableRow className="bg-secondary opacity-10">
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default CampaignComponent;
