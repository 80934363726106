import React, { useState, useEffect } from "react";

import LogoViolet from "../../../assets/images/Enel_X_Logo_Violet_RGB.png";
import LogoWhite from "../../../assets/images/Enel_X_Logo_White_RGB.png";
const Navbar = () => {
  const TOP_OFFSET = 50;
  const [showBackgroundNavbar, setShowBackgroundNavbar] =
    useState<boolean>(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= TOP_OFFSET) {
        setShowBackgroundNavbar(true);
      } else {
        setShowBackgroundNavbar(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <nav
      className={`fixed z-40 w-full ${showBackgroundNavbar ? "bg-white" : "bg-transparent"}`}
    >
      <div className="max-w-screen flex lg:justify-between">
        <div className="ml-10 flex w-full items-center justify-start px-5 lg:justify-center lg:px-0 xl:w-2/6 2xl:w-2/6">
          <div className="my-5 -ml-7 w-40 pt-5 lg:ml-0 lg:w-56 lg:pt-2">
            {showBackgroundNavbar ? (
              <img
                alt="enelx_logo"
                src={LogoViolet}
                className="img-fluid w-full"
              />
            ) : (
              <img
                alt="enelx_logo"
                src={LogoWhite}
                className="img-fluid w-full"
              />
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};
export default Navbar;
