import React from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import styled from "styled-components";
import ReCAPTCHA from "react-google-recaptcha";

import Input from "../../../inputs/Input";
import PrimaryButton from "../../../buttons/PrimaryButton";
import { useStepContext } from "../stepper.provider";
import { useLoginClaimService } from "../../../../services/microservice/claim/auth/use-auth";
import { useLoginClaim } from "../../../../hooks/useLoginClaim";

type IFormInput = {
  username: string;
  password: string;
  confirm: boolean;
  captcha: string;
};

interface ICustomStepProps {
  colorPrimary: string;
  privacyPolicy?: React.ReactNode;
}

const LabelCheckbox = styled.label`
  > input[type="checkbox"]:checked + i {
    background: "#000000" !important;
  }
`;

const schemaLogin = yup
  .object({
    username: yup
      .string()
      .matches(/^\d+$/, { message: " Requerido" })
      .required(" Requerido"),
    password: yup.string().email().required(" Requerido"),
    confirm: yup.boolean().oneOf([true], "* Requerido"),
    captcha: yup.string().required("* Requerido"),
  })
  .required();

const LoginStepComponent = ({
  colorPrimary,
  privacyPolicy,
}: ICustomStepProps) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schemaLogin),
  });
  const { mutateAsync: loginClaim, isLoading } = useLoginClaimService();
  const { login } = useLoginClaim();
  const { setCurrentStep } = useStepContext();

  const onSubmit: SubmitHandler<IFormInput> = async (val) => {
    try {
      const res = await loginClaim({
        username: val.username,
        password: val.password,
        captcha: val.captcha,
      });
      login(res.data, false);
      setCurrentStep(2);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <section className="w-full">
      <h2 className="mb-5 text-center text-3xl font-bold">
        1. <span style={{ color: colorPrimary }}>Identifícate</span>
      </h2>
      <div className="mb-10 w-full">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="username"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                classNameContainer="w-full mb-7"
                classNameInput="w-full"
                placeholder="Número de identificación"
                error={errors.username?.message}
                required
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                classNameContainer="w-full my-2"
                classNameInput="w-full"
                placeholder="Email"
                error={errors.password?.message}
                required
              />
            )}
          />
          {!privacyPolicy && (
            <div className="mt-4">
              <LabelCheckbox className="w-full text-sm">
                <input
                  {...register("confirm", {
                    required: {
                      value: true,
                      message: "* Requerido",
                    },
                  })}
                  type="checkbox"
                />
                <i></i> Acepta{" "}
                <a
                  href="https://files-statics-protegeme.s3.amazonaws.com/P1648+EQUIPOS+TELEFONIA+CELULAR_MAR+2022.pdf"
                  target="_blank"
                  rel="noreferrer"
                  style={{ whiteSpace: "nowrap", color: "black" }}
                >
                  términos y condiciones.
                </a>
              </LabelCheckbox>
              <div className="h-1">
                {errors && (
                  <p className="my-0 py-0 text-sm text-critical">
                    {errors.confirm?.message}
                  </p>
                )}
              </div>
              <p className="mt-4 text-sm">
                <a
                  className=""
                  href="https://files-statics-protegeme.s3.amazonaws.com/Politica+deprotecciondedatos-min.pdf"
                  target="_blank"
                  rel="noreferrer"
                  style={{ whiteSpace: "nowrap", color: "black" }}
                >
                  Política de tratamiento de datos.
                </a>
              </p>
            </div>
          )}
          <ReCAPTCHA
            sitekey="6LfNIoMqAAAAAK_vEXRrqFSloQMnKXBNNSc3nyo4"
            onChange={(e) => setValue("captcha", e)}
            className="my-2"
          />
          <div className="h-1">
            {errors && watch("captcha") === undefined && (
              <p className="my-0 py-0 text-sm text-critical">
                {errors.captcha?.message}
              </p>
            )}
          </div>
          <PrimaryButton
            title="INICIAR SESIÓN"
            className={
              "mt-5 rounded-3xl bg-buttonBlack px-5 py-1 text-sm font-normal text-whiteSoft"
            }
            disabled={isLoading}
            loading={isLoading}
          />
          {privacyPolicy}
        </form>
      </div>
    </section>
  );
};

export default LoginStepComponent;
